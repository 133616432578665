import React from "react";
import "./InputField.css"; // Import the CSS file for styling

const InputField = ({ label, value, onChange, name, error }) => {
  return (
    <div className="input-field">
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        className={`input ${error ? "input-error" : ""}`} // Apply "input-error" class if there's an error
        required
      />
      <label className={value ? "filled" : ""}>{label}</label>
      {error && <span className="error-message">{error}</span>}{" "}
      {/* Display error message if it exists */}
    </div>
  );
};

export default InputField;

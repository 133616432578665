import React from "react";
import "./Modal.css"; // Import the CSS file for styling
import closeIcon from "../../assets/Icons/closeIcon.svg";

const Modal = ({ title, children, onClose }) => {
  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <div className="modal-header">
          <h2>{title}</h2>
          <button className="modal-close-button" onClick={onClose}>
            <img src={closeIcon} alt="Close" className="close-icon" />
          </button>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;

import React from "react";
import "./CustomCheckbox.css"; // Assuming you have styles in this CSS file

const CustomCheckbox = ({ checked, title, onChange }) => {
  return (
    <div className="custom-checkbox-container">
      <label className="custom-checkbox">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="checkmark"></span>
      </label>
      <p className="checkbox-title">{title}</p>
    </div>
  );
};

export default CustomCheckbox;

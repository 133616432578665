import React from "react";
import "./MobileHeader.css"; // Import the CSS file for styling
import myburgologo from "../../assets/Logo/myburgoLogo.svg";

const MobileHeader = () => {
  return (
    <div className="mobile-header">
      <img
        src={myburgologo} // Replace with the path to your image
        alt="myburgo Logo"
        className="header-logo"
      />
    </div>
  );
};

export default MobileHeader;

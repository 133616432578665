import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SessionQuestionCard from "../../../components/Card/SessionQuestionCard";
import addIcon from "../../../assets/Icons/plusIcon.svg";
import Chips from "../../../components/Chip/Chips";
import Cookies from "js-cookie";
import { open_api_with_user_token } from "../../../Utils/network";
import { addAlert, removeAlert } from "../../../Utils/helper";
import CustomAlert from "../../../components/Alerts/CustomAlert";

const AllQuestions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [packageQuestionsData, setPackageQuestionsData] = useState([]);
  const [formattedPackageQuestionsData, setFormattedPackageQuestionsData] =
    useState([]);
  const [alerts, setAlerts] = useState([]); // Holds multiple alerts
  const [showAlert, setShowAlert] = useState(false); // Controls visibility of alerts

  // Navigate to add questions page
  const handleAddPackageClick = () => {
    navigate("/bookings/questions/add", { state: { isEditMode: false } });
  };

  useEffect(() => {
    getAllQuestionsData();

    // Check if there's any status passed from the AddQuestions page
    const { status, message } = location.state || {};
    if (status && message) {
      handleAddAlert(
        status === "success" ? "Action Successful" : "Action Failed",
        message,
        status === "success" ? "success" : "error",
        true
      );
    }
  }, [location.state]);

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
    setShowAlert(true); // Show alert when adding a new alert
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
    if (alerts.length <= 1) {
      setShowAlert(false); // Hide alert if there are no more alerts to show
    }
  };

  // Fetch all questions data when component mounts
  useEffect(() => {
    getAllQuestionsData();
  }, []);

  const getAllQuestionsData = async () => {
    const expertID = Cookies.get("expertID");
    try {
      const res = await open_api_with_user_token.get(
        `/session/dashboard/experts/${expertID}/questions/`
      );
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        console.log(res.data.results);
        setPackageQuestionsData(res.data.results); // Set the data array directly
      }
    } catch (error) {
      console.error("Error fetching booking stats data", error);
    }
  };

  // Format package questions data whenever packageQuestionsData changes
  useEffect(() => {
    if (packageQuestionsData.length > 0) {
      const formattedPackageQuestionsData =
        mapApiDataToPackageQuestion(packageQuestionsData);
      setFormattedPackageQuestionsData(formattedPackageQuestionsData);
    }
  }, [packageQuestionsData]);

  // Map the API data to the desired card data format
  const mapApiDataToPackageQuestion = (packageQuestionsData) => {
    return packageQuestionsData.map((packageData) => {
      const questionCount = packageData.session_packages_questions.length;
      console.log(packageData);
      return {
        id: packageData.id,
        title: packageData.title,
        subtitle: `${questionCount} Questions`,
        active: packageData.is_user_data_required,
      };
    });
  };

  const handleSubmitStatus = (status, message) => {
    if (status === "success") {
      handleAddAlert("Action Successful", message, "success", true);
    } else {
      handleAddAlert("Action Failure", message, "error", true);
    }
  };

  return (
    <div className="main-container">
      <div className="all-packages-container">
        <div className="all-packages-header">
          <div className="all-packages-header-left">
            <h2>Registration Questions</h2>
          </div>
          <div className="all-packages-header-right">
            <Chips
              icon={addIcon}
              text="Add session Package questions"
              onClick={handleAddPackageClick}
            />
          </div>
        </div>
        {/* Render multiple SessionCards */}
        {formattedPackageQuestionsData.map((packageQuestionData, index) => (
          <SessionQuestionCard
            key={index}
            id={packageQuestionData.id}
            title={packageQuestionData.title}
            subtitle={packageQuestionData.subtitle}
            isActive={packageQuestionData.active}
          />
        ))}
      </div>
      {showAlert &&
        alerts.map((alert) => (
          <CustomAlert
            key={alert.id}
            title={alert.title}
            message={alert.message}
            onClose={() => handleRemoveAlert(alert.id)}
            autoClose={alert.autoClose}
            type={alert.type}
          />
        ))}
    </div>
  );
};

export default AllQuestions;

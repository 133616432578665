import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaEdit } from "react-icons/fa";
import ToggleButtons from "../Button/ToggleButtons";
import "./CalendarWithSlots.css";
import editIcon from "../../assets/Icons/editIcon.svg";
import { open_api_with_user_token } from "../../Utils/network"; // Import your API utility
import Cookies from "js-cookie";
import LoadingOverlay from "../Loading/LoadingOverlay";
import { removeAlert, addAlert } from "../../Utils/helper";
import CustomAlert from "../Alerts/CustomAlert";
import Modal from "../Dialog/Modal"; // Import the Modal component for confirmation dialog
import Button from "../Button/Button";

const CalendarWithSlots = ({ refreshSlots }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedPeriod, setSelectedPeriod] = useState("morning");
  const [isEditMode, setIsEditMode] = useState(false); // Track if in edit mode
  const [selectedSlots, setSelectedSlots] = useState([]); // Track selected slots
  const [slotsData, setSlotsData] = useState([]); // Store fetched slot data
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [availableDates, setAvailableDates] = useState([]); // Dates with available slots
  const [refreshKey, setRefreshKey] = useState(0);
  const [alerts, setAlerts] = useState([]); // Holds multiple alerts
  const [showAlert, setShowAlert] = useState(false); // Controls visibility of alerts

  const [showConfirmModal, setShowConfirmModal] = useState(false); // Show confirmation modal

  // Fetch all available dates when the component mounts
  useEffect(() => {
    const fetchAvailableDates = async () => {
      try {
        const expertID = Cookies.get("expertID");
        let nextPage = 1;
        let allSlots = [];
        const dateSet = new Set(); // Use a set to avoid duplicate dates

        while (nextPage) {
          const response = await open_api_with_user_token.get(
            `/session/all-expert_slot/?expert=${expertID}&page=${nextPage}`
          );

          // Assuming each slot has a 'date' field, extract and add it to the set
          response?.data?.data?.forEach((slot) => {
            dateSet.add(slot.date);
          });

          // Update nextPage with the next page link or set it to null if there's no next page
          nextPage = response.data.next_page ? nextPage + 1 : null;
        }

        // Convert the dateSet to an array of Date objects and update the state
        setAvailableDates(Array.from(dateSet).map((date) => new Date(date)));
      } catch (err) {
        console.error("Failed to fetch available dates:", err);
      }
    };

    fetchAvailableDates();
  }, [refreshKey, refreshSlots]);

  // Fetch slots data from API when selectedDate changes
  useEffect(() => {
    const fetchSlots = async () => {
      setIsLoading(true); // Start loading
      setError(null); // Reset error state
      try {
        const expertID = Cookies.get("expertID");
        const formattedDate = selectedDate.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
        const response = await open_api_with_user_token.get(
          `/session/all-expert_slot/?expert=${expertID}&date=${formattedDate}`
        );
        setSlotsData(response?.data?.data); // Update slots data
      } catch (err) {
        console.error("Failed to fetch slots:", err);
        setError("Failed to fetch slots. Please try again later.");
      } finally {
        setIsLoading(false); // Stop loading
      }
    };

    fetchSlots();
  }, [selectedDate, refreshKey]); // Run effect when selectedDate changes

  // Helper function to categorize time into morning, afternoon, night
  const getPeriod = (time) => {
    const hours = parseInt(time.split(":")[0], 10);
    if (hours >= 5 && hours < 12) return "morning";
    if (hours >= 12 && hours < 17) return "afternoon";
    return "night";
  };

  // Filter slots based on selected date and time period
  const filteredSlots =
    slotsData.length > 0
      ? slotsData?.filter((slot) => getPeriod(slot.time) === selectedPeriod)
      : [];

  // Helper function to format duration
  const formatDuration = (duration) => {
    const [hours, minutes] = duration.split(":").map(Number);
    return `${hours * 60 + minutes} mins`; // Convert hours to minutes and add minutes
  };

  // Handle selecting/deselecting a time chip
  const toggleSelectSlot = (slot) => {
    if (selectedSlots.includes(slot)) {
      setSelectedSlots(selectedSlots.filter((s) => s !== slot));
    } else {
      setSelectedSlots([...selectedSlots, slot]);
    }
  };

  // Render the time chips with selection logic
  const renderTimeChips = () => {
    return filteredSlots.map((slot) => {
      const isAvailable = slot.available; // Check if the slot is available

      return (
        <div
          key={slot.id}
          className={`time-chip ${
            selectedSlots.includes(slot) ? "selected" : ""
          } ${!isAvailable ? "disabled" : ""}`} // Add 'disabled' class if the slot is not available
          onClick={() => isEditMode && isAvailable && toggleSelectSlot(slot)} // Allow click only in edit mode and if available
        >
          {isEditMode && (
            <div
              className={`selection-circle ${!isAvailable ? "disabled" : ""}`}
            >
              {selectedSlots.includes(slot) && (
                <div className="inner-circle"></div>
              )}
            </div>
          )}

          {/* Time */}
          {new Date(`1970-01-01T${slot.time}`).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
          {/* Duration */}
          <div className="duration">{formatDuration(slot.duration)}</div>
        </div>
      );
    });
  };

  // Handle cancel button click
  const handleCancel = () => {
    setSelectedSlots([]); // Clear selected slots
    setIsEditMode(false); // Exit edit mode
  };

  // Show confirmation modal on delete button click
  const handleDelete = () => {
    setShowConfirmModal(true);
  };

  // Confirm deletion of slots
  const confirmDelete = async () => {
    // Extract slot IDs from selectedSlots array
    const selectedSlotIds = selectedSlots.map((slot) => slot.id);

    const payload = { slot_ids: selectedSlotIds };

    try {
      // Use the DELETE method and pass payload as data
      const response = await open_api_with_user_token.delete(
        "/session/expert_slot/delete_multiple_slots/",
        { data: payload } // Pass payload correctly as data for DELETE request
      );

      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        // Successful deletion, update state
        const updatedSlotsData = slotsData?.filter(
          (slot) => !selectedSlotIds.includes(slot.id)
        );
        handleAddAlert(
          "Action Successful",
          "Slots deleted Successfully",
          "success",
          true
        );
        setSlotsData(updatedSlotsData); // Update state with remaining slots
        setSelectedSlots([]); // Clear selected slots
        setIsEditMode(false); // Exit edit mode
        setRefreshKey((prev) => prev + 1);
      } else if (
        response.status === 400 &&
        response.data.error ===
          "Some slots cannot be deleted as they are already booked."
      ) {
        // Handle the case where some slots cannot be deleted
        handleAddAlert(
          "Action Failed",
          "Some slots were not deleted",
          "error",
          true
        );
      }
    } catch (error) {
      console.error("Failed to delete slots:", error);
      alert("Failed to delete slots. Please try again.");
    } finally {
      setShowConfirmModal(false); // Close confirmation modal
    }
  };

  // Handle cancel button in confirmation modal
  const cancelDelete = () => {
    setShowConfirmModal(false); // Close confirmation modal
  };

  const availableDatesSet = new Set(
    availableDates.map((date) => date.toDateString())
  );

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
    setShowAlert(true); // Show alert when adding a new alert
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
    if (alerts.length <= 1) {
      setShowAlert(false); // Hide alert if there are no more alerts to show
    }
  };

  return (
    <>
      <div className="calendar-slots-container">
        {/* Calendar Section */}
        <div className="calendar-section">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            inline
            className="custom-calendar" // Custom styles
            highlightDates={availableDates}
            dayClassName={(date) =>
              availableDatesSet.has(date.toDateString())
                ? "highlight-slot-dates"
                : undefined
            }
          />
        </div>

        {/* Time Slots Section */}
        <div className="slots-section">
          {/* Edit Icon */}
          <div className="edit-icon" onClick={() => setIsEditMode(!isEditMode)}>
            <img src={editIcon} className="edit-icon-img" alt="Edit" />
          </div>

          {/* Toggle Section using the ToggleButtons component */}
          <ToggleButtons onSelect={(period) => setSelectedPeriod(period)} />

          {/* Time Slots */}
          <div className="time-chips-container">
            {isLoading ? (
              <LoadingOverlay />
            ) : error ? (
              <div className="error-message">{error}</div>
            ) : (
              renderTimeChips()
            )}
          </div>

          {/* Action Buttons */}
          {isEditMode && (
            <div className="action-buttons">
              <button className="cancel-button" onClick={handleCancel}>
                Cancel
              </button>
              <button className="delete-button" onClick={handleDelete}>
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
      {showConfirmModal && (
        <Modal
          title="Confirm Deletion"
          onClose={cancelDelete} // Close modal on cancel
        >
          <div>Are you sure you want to delete the selected slots?</div>
          <div className="confirmation-buttons">
            <Button
              text="cancel"
              backgroundColor="#ccc"
              onClick={cancelDelete}
            ></Button>
            <Button
              text="Delete"
              backgroundColor="#D11A2A "
              onClick={confirmDelete}
            ></Button>
          </div>
        </Modal>
      )}
      {showAlert &&
        alerts.map((alert) => (
          <CustomAlert
            key={alert.id}
            title={alert.title}
            message={alert.message}
            onClose={() => handleRemoveAlert(alert.id)}
            autoClose={alert.autoClose}
            type={alert.type}
          />
        ))}
    </>
  );
};

export default CalendarWithSlots;

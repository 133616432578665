import React from "react";
import "./ToggleButton.css"; // Import the CSS file for styling

const ToggleButton = ({ isToggled, onToggle }) => {
  return (
    <div
      className={`toggle-button ${isToggled ? "on" : "off"}`}
      onClick={onToggle}
    >
      <div className="toggle-switch" />
    </div>
  );
};

export default ToggleButton;

import React, { useState, useRef } from "react";
import "./DropdownCard.css";
import { FaChevronDown } from "react-icons/fa";

const DropdownCard = ({ options, title, onSelect, selected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    selected || title || "Select an option"
  );

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onSelect) onSelect(option); // Notify parent about the selection
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="dropdown-card" ref={dropdownRef}>
      <div className="dropdown-card-header" onClick={toggleDropdown}>
        <span className="dropdown-card-selected">{selectedOption}</span>
        <FaChevronDown
          className={`dropdown-card-icon ${isOpen ? "open" : ""}`}
        />
      </div>
      {isOpen && (
        <div className="dropdown-card-menu">
          {options.map((option, index) => (
            <div
              key={index}
              className="dropdown-card-option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownCard;

import React, { useState } from "react";
import "./SessionCard.css"; // Import the CSS file for styling
import { FaEllipsisV } from "react-icons/fa"; // Icon for the vertical dots menu
import ToggleButton from "../Button/ToggleButton";
import { open_api_with_user_token } from "../../Utils/network";
import editIcon from "../../assets/Icons/editIcon.svg";
import AddPackage from "../../pages/Bookings/BookingsPackage/AddPackage";
import ShareModal from "../Dialog/ShareModal";
import shareIcon from "../../assets/Icons/shareIcon.svg";
import { FaTrashAlt } from "react-icons/fa";
import Modal from "../Dialog/Modal";
import Button from "../Button/Button";
const SessionCard = ({
  data,
  handleSubmitStatus,
  cardFormData,
  expertSlug,
}) => {
  const [isToggled, setIsToggled] = useState(data.isActive || false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const toggleSwitch = () => {
    setIsToggled(!isToggled);
    updateSessionActiveState(!isToggled);
  };
  const [formData, setFormData] = useState(cardFormData);

  const updateSessionActiveState = async (isActive) => {
    try {
      const localData = {
        published: isActive,
      };
      const res = await open_api_with_user_token.patch(
        `/session/packages/${data.id}/`,
        localData
      );
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        console.log("status updated");
      }
    } catch (error) {
      console.error("Error fetching booking stats data", error);
    }
  };

  const getSessionPackageData = async () => {
    try {
      const res = await open_api_with_user_token.get(
        `/session/packages/${data.id}/`
      );
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        return res.data.data;
      }
    } catch (error) {
      console.error("Error fetching booking stats data", error);
    }
  };

  const deleteSessionPackage = async () => {
    try {
      const res = await open_api_with_user_token.delete(
        `/session/packages/${data.id}/`
      );
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error fetching booking stats data", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleDelete = () => {
    setShowConfirmModal(true);
  };

  const cancelDelete = () => {
    setShowConfirmModal(false); // Close confirmation modal
  };

  const toggleModal = async () => {
    if (!isModalOpen) {
      const packageData = await getSessionPackageData(); // Wait for the data to be fetched
      console.log(packageData);
      if (packageData) {
        const formData = {
          id: packageData.id,
          sessionName: packageData.title || "",
          sessionsPerPackage: packageData.sessions_count || "",
          duration: packageData.slot_duration?.duration_name || "15 mins", // Assuming slot_duration has a name field
          priceInr: packageData.selling_price || "",
          priceUsd: packageData.selling_price_usd || "",
          is_user_data_required: packageData.is_user_data_required || false,
        };
        setFormData(formData); // Set the formData state with the fetched data
      }
    }
    setIsModalOpen(!isModalOpen);
  };

  const toggleShareModal = () => {
    setIsShareModalOpen(!isShareModalOpen); // Toggle the share modal visibility
  };

  return (
    <>
      <div className="session-card">
        {/* Header Section */}
        <div className="session-card-header">
          <div className="session-card-header-left">
            <h2>{data.title}</h2>
          </div>
          <div className="session-card-header-right">
            {/* Toggle Button */}

            <div className="share-icon-item" onClick={toggleShareModal}>
              <img src={shareIcon} alt="Share" />
            </div>

            <div className="edit-icon-item" onClick={toggleModal}>
              <img src={editIcon} />
            </div>

            {/* Menu Button with Dropdown */}
            {/* <div className="menu-container">
            {" "}
            
            <div className="menu-button" onClick={toggleMenu}>
            <FaEllipsisV />
            </div>
            
            {isMenuOpen && (
              <div className="dropdown-menu">
                <div className="menu-item">Edit</div>
                <div className="menu-item" onClick={handleDelete}>
                  Delete
                </div>
              </div>
            )}
          </div> */}
          </div>
        </div>

        {/* Session Info Section */}
        <div className="session-card-info">
          <span>{data.duration}</span>
          <span className="divider-session-card">|</span>
          <span>{data.sessions}</span>
          <span className="divider-session-card">|</span>
          {data.is_user_data_required ? (
            <>
              <span>user information is required</span>
            </>
          ) : (
            <>
              <span>user information is not required</span>
            </>
          )}
        </div>

        {/* Horizontal Divider */}
        <div className="divider-horizontal"></div>

        {/* Data Sections */}
        <div className="session-card-details-container">
          <div className="session-card-details">
            {data.details.map((detail, index) => (
              <div key={index} className="session-card-detail">
                <span>{detail.label}</span>
                <span className="price">{detail.value}</span>
              </div>
            ))}
          </div>
          <div className="session-card-detail">
            <FaTrashAlt
              className="action-icon"
              title="Delete"
              onClick={handleDelete}
            />
            <span className="divider-session-card">|</span>
            <span>Enable</span>
            <ToggleButton isToggled={isToggled} onToggle={toggleSwitch} />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <AddPackage
          onClose={toggleModal}
          onSubmitStatus={handleSubmitStatus}
          parentFormData={formData}
          isEditMode={true}
        />
      )}
      {isShareModalOpen && (
        <ShareModal
          onClose={toggleShareModal}
          shareLink={`https://www.myburgo.com/${expertSlug}/bookings`}
        />
      )}
      {showConfirmModal && (
        <Modal
          title="Confirm Deletion"
          onClose={cancelDelete} // Close modal on cancel
        >
          <div>Are you sure you want to delete the selected slots?</div>
          <div className="confirmation-buttons">
            <Button
              text="cancel"
              backgroundColor="#ccc"
              onClick={cancelDelete}
            ></Button>
            <Button
              text="Delete"
              backgroundColor="#D11A2A "
              onClick={deleteSessionPackage}
            ></Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SessionCard;

import React, { useState, useRef, useEffect } from "react";
import "./DropdownField.css";

const DropdownField = ({ label, options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false); // Dropdown open state
  const dropdownRef = useRef(null);

  // Handle clicking outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleOptionSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  // Determine if the label should float based on the value or dropdown state
  const isFilled = value || isOpen;

  return (
    <div className="dropdown-field" ref={dropdownRef}>
      <div
        className="dropdown-header-custom"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`dropdown-label ${isFilled ? "filled" : ""}`}>
          {label}
        </span>
        <span className="dropdown-selected-value">{value || label}</span>
        <span className={`dropdown-arrow ${isOpen ? "open" : ""}`}>
          &#9662;
        </span>
      </div>
      {isOpen && (
        <div className="dropdown-card-menu">
          {options.map((option, index) => (
            <div
              key={index}
              className="dropdown-card-option"
              onClick={() => handleOptionSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownField;

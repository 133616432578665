import React from "react";
import "./StatsCard.css"; // Import the CSS file for styling
import verticalLine from "../../assets/Icons/verticalLine.svg";

const StatsCard = ({ data }) => {
  return (
    <div className="stats-card">
      {data.map((section, index) => (
        <React.Fragment key={index}>
          <div className="stats-section">
            <div className="icon-wrapper">
              <img src={section.icon} alt={section.title} className="icon" />
            </div>
            <div className="stats-content">
              <p className="title">{section.title}</p>
              <p className="value">{section.value}</p>
            </div>
          </div>
          {/* Add the vertical line between sections, except the last one */}
          {index < data.length - 1 && (
            <div className="separator">
              <img
                src={verticalLine}
                alt="separator"
                className="separator-line"
              />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default StatsCard;

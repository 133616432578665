import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./TimeField.css"; // Custom styling file
import moment from "moment-timezone";

const TimeField = ({ label, selectedTime, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);

  // Convert to IST
  const convertToIST = (date) => {
    return date ? moment(date).tz("Asia/Kolkata").toDate() : null;
  };

  // Convert from IST to UTC
  const convertFromIST = (date) => {
    return date ? moment.tz(date, "Asia/Kolkata").utc().toDate() : null;
  };

  // Convert selectedTime to IST for display
  const selectedTimeIST = convertToIST(selectedTime);

  // Handle change in time
  const handleChange = (date) => {
    onChange(convertFromIST(date));
  };

  return (
    <div className="time-field">
      <DatePicker
        selected={selectedTimeIST}
        onChange={handleChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        className="time-picker-input"
        onFocus={() => setIsFocused(true)} // Set focus state to true on focus
        onBlur={() => setIsFocused(false)} // Set focus state to false on blur
        placeholderText="" // Remove default placeholder to control label animation
      />
      <label
        className={`time-label ${isFocused || selectedTime ? "filled" : ""}`}
      >
        {label}
      </label>
    </div>
  );
};

export default TimeField;

import React, { useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import "./ShareModal.css";
import { addAlert, removeAlert } from "../../Utils/helper";
import CustomAlert from "../Alerts/CustomAlert";

const ShareModal = ({ onClose, shareLink }) => {
  const [alerts, setAlerts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
    handleAddAlert("Link copied", "Link copied to clipboard!", "success", true);
  };

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
    setShowAlert(true);
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
    if (alerts.length <= 1) {
      setShowAlert(false);
    }
  };

  return (
    <div className="share-modal-overlay">
      <div className="share-modal">
        <h2>Share this Package</h2>
        <p>Share this package link with others:</p>
        <div className="share-link-container">
          <input type="text" value={shareLink} readOnly />
          <button onClick={handleCopyLink} className="copy-button">
            Copy Link
          </button>
        </div>
        <div className="share-buttons">
          <FacebookShareButton url={shareLink}>
            <button className="facebook-share">Facebook</button>
          </FacebookShareButton>
          <TwitterShareButton url={shareLink}>
            <button className="twitter-share">Twitter</button>
          </TwitterShareButton>
          <LinkedinShareButton url={shareLink}>
            <button className="linkedin-share">LinkedIn</button>
          </LinkedinShareButton>
        </div>
        <button onClick={onClose} className="close-modal">
          Close
        </button>
      </div>
      {showAlert &&
        alerts.map((alert) => (
          <CustomAlert
            key={alert.id}
            title={alert.title}
            message={alert.message}
            onClose={() => handleRemoveAlert(alert.id)}
            autoClose={alert.autoClose}
            type={alert.type}
          />
        ))}
    </div>
  );
};

export default ShareModal;

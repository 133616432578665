import React from "react";
import { FaSearch } from "react-icons/fa"; // Import the search icon
import "./SearchBar.css"; // Style file for the search bar

const SearchBar = ({ onSearch }) => {
  const handleSearch = (event) => {
    onSearch(event.target.value);
  };

  return (
    <div className="search-bar">
      <FaSearch className="search-icon" />
      <input
        type="text"
        placeholder="Search"
        onChange={handleSearch}
        className="search-input"
      />
    </div>
  );
};

export default SearchBar;

import React from "react";
import "./Button.css"; // Import the CSS file for styling

const Button = ({
  text,
  onClick,
  backgroundColor = "#FF67A1",
  marginBottom = "0px",
  ...props
}) => {
  return (
    <button
      className="custom-button"
      onClick={onClick}
      style={{ backgroundColor, marginBottom }} // Apply background color dynamically
      {...props}
    >
      {text}
    </button>
  );
};

export default Button;

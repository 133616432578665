import React from "react";
import "./Chips.css";

const Chips = ({ icon, text, onClick }) => {
  return (
    <div className="chips" onClick={onClick}>
      {icon && <img src={icon} alt={text} className="chips-icon" />}
      <span className="chips-text">{text}</span>
    </div>
  );
};

export default Chips;

import React from "react";
import "./TableCard.css";
import { Link } from "react-router-dom";
import emptyIcon from "../../assets/Icons/emptyIcon.svg";
import LoadingOverlay from "../Loading/LoadingOverlay";

const TableCard = ({
  columns,
  data,
  totalEntries,
  currentPage,
  totalPages,
  onPageChange,
  isClickable,
  loading,
  isShowJoinButton,
}) => {
  const entriesPerPage = 10; // Set entries per page to 10

  // Calculate entry range
  const entriesStart = (currentPage - 1) * entriesPerPage + 1;
  const entriesEnd = Math.min(currentPage * entriesPerPage, totalEntries);

  return (
    <div className="table-card">
      {loading ? (
        <LoadingOverlay />
      ) : (
        <div className="table-container">
          <div className="table-header">
            {columns.map((col, index) => (
              <div key={index} className="table-header-cell">
                {col.header}
              </div>
            ))}
            {isShowJoinButton ? (
              <>
                <div className="table-header-cell">Actions</div>{" "}
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="table-body">
            {data.length > 0 ? (
              data.map((row, rowIndex) => {
                const uniqueRowKey = `${row.id || rowIndex}-${rowIndex}`;
                const rowContent = (
                  <div
                    key={uniqueRowKey}
                    className={`table-row ${isClickable ? "clickable" : ""}`}
                  >
                    {columns.map((col, colIndex) => (
                      <div
                        key={`${uniqueRowKey}-${colIndex}`}
                        className="table-cell"
                      >
                        {col.accessor === "status" ? (
                          <span
                            className={`status-badge ${row[
                              col.accessor
                            ]?.toLowerCase()}`}
                          >
                            {row[col.accessor]}
                          </span>
                        ) : (
                          row[col.accessor]
                        )}
                      </div>
                    ))}
                    {isShowJoinButton ? (
                      <>
                        {" "}
                        <div className="table-cell join-button-cell">
                          <button className="join-button">Join</button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                );

                return isClickable ? (
                  <Link
                    to={`/bookings/details/${row.id}`}
                    key={`link-${uniqueRowKey}`}
                    className={`table-row-link ${
                      isClickable ? "clickable" : ""
                    }`}
                  >
                    {rowContent}
                  </Link>
                ) : (
                  <div
                    key={`link-${uniqueRowKey}`}
                    className="table-row-link-disabled"
                  >
                    {rowContent}
                  </div>
                );
              })
            ) : (
              <div className="empty-icon">
                <img src={emptyIcon} alt="No data" />
                <p>No bookings yet</p>
              </div>
            )}
          </div>
        </div>
      )}

      {!loading && (
        <div className="table-footer">
          <div className="table-footer-left">
            Showing {entriesStart} to {entriesEnd} of {totalEntries} entries
          </div>
          <div className="table-footer-right">
            <button
              disabled={currentPage === 1}
              onClick={() => onPageChange(currentPage - 1)}
            >
              &lt;
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={currentPage === index + 1 ? "active" : ""}
                onClick={() => onPageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              disabled={currentPage === totalPages}
              onClick={() => onPageChange(currentPage + 1)}
            >
              &gt;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableCard;

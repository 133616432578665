import React, { useState, useEffect } from "react";
import "./MultiSelectField.css"; // Import custom styling
import selectedIcon from "../../assets/Icons/selectedIcon.svg";
import deselectedIcon from "../../assets/Icons/deselectedIcon.svg";
import CustomCheckbox from "./CustomCheckbox";

const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const MultiSelectField = ({ selectedDays, onSelectDay }) => {
  const [selectAll, setSelectAll] = useState(false);

  // Handle select or deselect all days
  const handleSelectAll = () => {
    if (!selectAll) {
      onSelectDay(daysOfWeek);
    } else {
      onSelectDay([]);
    }
    setSelectAll(!selectAll);
  };

  // Handle individual day selection
  const toggleDaySelection = (day) => {
    if (selectedDays.includes(day)) {
      onSelectDay(selectedDays.filter((selectedDay) => selectedDay !== day));
    } else {
      onSelectDay([...selectedDays, day]);
    }
  };

  useEffect(() => {
    if (selectedDays.length === daysOfWeek.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedDays]);

  return (
    <div className="multi-select-field">
      <p className="multi-select-label">Set slots for these days of the week</p>
      <div className="days-container">
        {daysOfWeek.map((day) => (
          <div
            key={day}
            className={`day-button ${
              selectedDays.includes(day) ? "selected" : ""
            }`}
            onClick={() => toggleDaySelection(day)}
          >
            <span>{day}</span>
            <div className="circle">
              {selectedDays.includes(day) ? (
                <img
                  src={selectedIcon}
                  alt="Selected"
                  className="checkmark-icon"
                />
              ) : (
                <img
                  src={deselectedIcon}
                  alt="Deselected"
                  className="checkmark-icon"
                />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="select-all">
        <CustomCheckbox
          checked={selectAll}
          onChange={handleSelectAll}
          title="Select all days"
        />
      </div>
    </div>
  );
};

export default MultiSelectField;

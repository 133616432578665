import React, { useEffect } from "react";
import LoginCard from "../../components/Card/LoginCard";
import myburgoLogo from "../../assets/Logo/myburgoLogoWithTagLine.svg";
import "./LoginPage.css";
import { useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";

const LoginPage = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/bookings/dashboard");
      window.location.reload();
    }
  }, [isAuthenticated]);

  return (
    <div className="login-container">
      <div className="login-container-content">
        <img
          src={myburgoLogo}
          alt="my burgo logo"
          className="login-logo-container"
        />
        <LoginCard />
      </div>
    </div>
  );
};

export default LoginPage;

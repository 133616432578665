import React, { useState } from "react";
import "./InputField.css";

const NumberField = ({ label, value, onChange, name, error }) => {
  const [internalError, setInternalError] = useState("");

  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Allow empty value, or a valid number including decimals and negatives
    if (/^-?\d*\.?\d*$/.test(inputValue) || inputValue === "") {
      setInternalError(""); // Reset the error message
      onChange(e); // Call the parent component's onChange handler
    } else {
      setInternalError("Only numbers are allowed");
    }
  };

  return (
    <div className="input-field">
      <input
        type="number" // Set input type to number
        name={name}
        value={value}
        onChange={handleChange}
        className={`input ${error ? "input-error" : ""}`}
        required
      />
      <label className={value ? "filled" : ""}>{label}</label>
      {internalError || error ? (
        <span className="error-message">{internalError || error}</span>
      ) : null}{" "}
      {/* Display internal error or passed error message if it exists */}
    </div>
  );
};

export default NumberField;

import React, { useState } from "react";
import "./ToggleButtons.css";
import morningIcon from "../../assets/Icons/morningIcon.svg";
import afternoonIcon from "../../assets/Icons/afternoonIcon.svg";
import nightIcon from "../../assets/Icons/nightIcon.svg";

const ToggleButtons = ({ onSelect }) => {
  const [selectedPeriod, setSelectedPeriod] = useState("morning");

  const handleToggle = (period) => {
    setSelectedPeriod(period);
    onSelect && onSelect(period);
  };

  return (
    <div className="toggle-buttons-container">
      {/* Background for the selected button */}
      <div className={`selected-background ${selectedPeriod}`} />

      {/* Toggle buttons */}
      <button
        className={`toggle-button-day ${
          selectedPeriod === "morning" ? "active" : ""
        }`}
        onClick={() => handleToggle("morning")}
      >
        <img
          src={morningIcon}
          alt="Afternoon Icon"
          className="toggle-button-icon"
        />
        Morning
      </button>
      <button
        className={`toggle-button-day ${
          selectedPeriod === "afternoon" ? "active" : ""
        }`}
        onClick={() => handleToggle("afternoon")}
      >
        <img
          src={afternoonIcon}
          alt="Afternoon Icon"
          className="toggle-button-icon"
        />
        Afternoon
      </button>
      <button
        className={`toggle-button-day ${
          selectedPeriod === "night" ? "active" : ""
        }`}
        onClick={() => handleToggle("night")}
      >
        <img
          src={nightIcon}
          alt="Afternoon Icon"
          className="toggle-button-icon"
        />
        Night
      </button>
    </div>
  );
};

export default ToggleButtons;

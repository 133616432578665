import React, { useEffect, useState } from "react";
import "./SessionReminder.css";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

const SessionReminder = ({
  id,
  sessionTitle,
  expertName,
  sessionTime,
  startTime,
  endTime,
  onJoinClick,
  isButtonDisabled, // State passed from the parent component
  isSessionStarted, // State passed from the parent component
}) => {
  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(startTime)
  );
  const [sessionText, setSessionText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Determine session text based on startTime
    const sessionText = getSessionText(startTime, sessionTime);
    setSessionText(sessionText);

    const timerId = setInterval(() => {
      const timeLeft = calculateTimeRemaining(startTime);
      if (timeLeft.total <= 0) {
        clearInterval(timerId); // Stop the timer when it reaches zero
        setTimeRemaining({
          total: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          days: 0,
        }); // Set timeRemaining to zero values
      } else {
        setTimeRemaining(timeLeft);
      }
    }, 1000);

    return () => clearInterval(timerId); // Clean up timer on component unmount
  }, [startTime]);

  // Helper function to calculate remaining time
  function calculateTimeRemaining(startTime) {
    // Convert startTime to a Date object in UTC
    const startTimeUTC = new Date(startTime);

    // Get the current time
    const currentTimeUTC = new Date();

    // Calculate the difference in milliseconds
    const total = startTimeUTC - currentTimeUTC;

    // If the total time remaining is less than or equal to zero, return zero values
    if (total <= 0) {
      return {
        total: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    // Calculate total seconds remaining
    const totalSeconds = Math.floor(total / 1000);

    // Calculate days, hours, minutes, and seconds
    const days = Math.floor(totalSeconds / (3600 * 24)); // 3600 seconds * 24 hours = seconds in a day
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600); // Remaining hours after removing days
    const minutes = Math.floor((totalSeconds % 3600) / 60); // Remaining minutes after removing hours
    const seconds = totalSeconds % 60; // Remaining seconds after removing minutes

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  }

  // Helper function to determine the session text based on startTime
  function getSessionText(startTime, sessionTime) {
    const sessionDate = new Date(startTime);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = sessionDate.toLocaleDateString("en-US", options);

    if (
      sessionDate.getDate() === today.getDate() &&
      sessionDate.getMonth() === today.getMonth() &&
      sessionDate.getFullYear() === today.getFullYear()
    ) {
      return `You have an upcoming session with <strong>${expertName}</strong> today at <strong>${sessionTime}</strong>`;
    } else if (
      sessionDate.getDate() === tomorrow.getDate() &&
      sessionDate.getMonth() === tomorrow.getMonth() &&
      sessionDate.getFullYear() === tomorrow.getFullYear()
    ) {
      return `You have an upcoming session with <strong>${expertName}</strong> tomorrow at <strong>${sessionTime}</strong>`;
    } else {
      return `You have an upcoming session with <strong>${expertName}</strong> on <strong>${formattedDate}</strong> at <strong>${sessionTime}</strong>`;
    }
  }

  const handleRemainderCardClick = () => {
    navigate(`/bookings/details/${id}`);
  };

  const handleButtonClick = (e) => {
    e.stopPropagation(); // Stop the event propagation
    onJoinClick(); // Trigger the join action
  };

  return (
    <div
      className="session-reminder"
      onClick={() => handleRemainderCardClick()}
    >
      {/* Left Section */}
      <div className="session-info">
        <p dangerouslySetInnerHTML={{ __html: sessionText }} />
      </div>
      {/* Right Section */}
      <div className="session-timer">
        <span className="starts-in">
          <span className="starts-style">Starts </span>in
        </span>
        <div className="timer">
          {timeRemaining.days !== 0 || !timeRemaining.days ? (
            <>
              <div className="time-box">
                <span>{String(timeRemaining.days).padStart(2, "0")}</span>
                <span>D</span>
              </div>
              <span>:</span>
            </>
          ) : (
            <></>
          )}
          <div className="time-box">
            <span>{String(timeRemaining.hours).padStart(2, "0")}</span>
            <span>H</span>
          </div>
          <span>:</span>
          <div className="time-box">
            <span>{String(timeRemaining.minutes).padStart(2, "0")}</span>
            <span>M</span>
          </div>
          <span>:</span>
          <div className="time-box">
            <span>{String(timeRemaining.seconds).padStart(2, "0")}</span>
            <span>S</span>
          </div>
        </div>
        <Button
          text="Join now"
          onClick={handleButtonClick}
          backgroundColor={!isButtonDisabled ? "#FF67A1" : "#CCC"} // Use isButtonDisabled prop
        />
      </div>
    </div>
  );
};

export default SessionReminder;

import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import QuestionCard from "./QuestionCard";
import "./QuestionList.css";
import { open_api_with_user_token } from "../../Utils/network";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const QuestionList = ({ questions, setQuestions }) => {
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedQuestions = reorder(
      questions,
      result.source.index,
      result.destination.index
    );

    setQuestions(reorderedQuestions);
  };

  const addQuestion = () => {
    const newQuestion = {
      id: `new-question-${Date.now()}`, // Ensure ID is a string
      text: `Question ${questions.length + 1}`,
      type: "Multiple choice",
      isRequired: false,
      options: [{ id: `option-1`, text: "Option 1" }],
    };
    setQuestions([...questions, newQuestion]);
  };

  // Function to delete a question based on its ID
  const deleteQuestion = async (id) => {
    setQuestions(questions.filter((question) => question.id !== id));
    const res = await open_api_with_user_token.delete(
      `/session/questions/${id}/`
    );
  };

  const updateQuestion = (id, updatedQuestion) => {
    setQuestions(
      questions.map((question) =>
        question.id === id ? updatedQuestion : question
      )
    );
  };

  return (
    <div className="question-list-container">
      <div className="question-list-button-container">
        <button className="add-question-button" onClick={addQuestion}>
          Add Question
        </button>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        {questions.length > 0 && (
          <Droppable droppableId="questions">
            {(provided) => (
              <div
                className="question-list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {questions.map((question, index) => (
                  <Draggable
                    key={`question-${question.id}`} // Ensure the key is unique and stable
                    draggableId={`question-${question.id}`} // Ensure it's a string and matches the key
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={`question-card-wrapper ${
                          snapshot.isDragging ? "dragging" : ""
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        {/* Pass data, drag handle, and delete function to the QuestionCard */}
                        <QuestionCard
                          questionData={question}
                          dragHandleProps={provided.dragHandleProps}
                          onDelete={deleteQuestion}
                          onUpdate={updateQuestion} // Add an update handler
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>
    </div>
  );
};

export default QuestionList;

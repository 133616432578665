import React from "react";
import comingsoon from "../../assets/Icons/coming soon.png";
import "./Dashboard.css";

const Dashboard = () => {
  return (
    <div className="main-container">
      <div className="dashboard-container">
        <img src={comingsoon} className="coming-soon-image" />
        <p>coming soon</p>
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateField.css"; // Custom styling file

const DateField = ({ label, selectedDate, onChange, minDate, maxDate }) => {
  const [isFocused, setIsFocused] = useState(false);

  // Set today's date
  const today = new Date();

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className="date-field">
      <DatePicker
        selected={selectedDate} // Set default value to today's date
        onChange={onChange}
        dateFormat="dd MMM yyyy"
        className="date-picker-input"
        minDate={minDate} // Disable all dates before today
        maxDate={maxDate}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <label
        className={`date-label ${isFocused || selectedDate ? "filled" : ""}`}
      >
        {label}
      </label>
    </div>
  );
};

export default DateField;

import "./App.css";
import MainRouter from "./MainRouter/MainRouter";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        <MainRouter />
      </Router>
    </div>
  );
}

export default App;

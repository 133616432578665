import React, { useEffect, useState } from "react";
import Modal from "../../../components/Dialog/Modal";
import InputField from "../../../components/fields/InputField";
import DropdownField from "../../../components/fields/DropdownField";
import SubmitButton from "../../../components/Button/SubmitButton";
import NumberField from "../../../components/fields/NumberField";
import Cookies from "js-cookie";
import "./AllPackage.css";
import { formatDuration } from "../../../Utils/helper";
import {
  open_api_with_user_token,
  open_api_without_auth,
} from "../../../Utils/network";
import CustomCheckbox from "../../../components/fields/CustomCheckbox";

const AddPackage = ({
  onClose,
  onSubmitStatus,
  parentFormData,
  isEditMode,
}) => {
  const [durationList, setDurationList] = useState([]);
  const [durationMap, setDurationMap] = useState({}); // Map for duration_name to id
  const [formData, setFormData] = useState(parentFormData);
  const [isUserInfoRequired, setIsUserInfoRequired] = useState(
    parentFormData.is_user_data_required || false
  ); // Use parent data or false
  const [formErrors, setFormErrors] = useState({}); // State for validation errors

  useEffect(() => {
    getDurationList();
  }, []); // Run only once on component mount

  useEffect(() => {
    // Set initial duration value in the form data for edit mode
    if (isEditMode && parentFormData.duration) {
      console.log("parentFormData.duration", parentFormData.duration);
      setFormData((prevFormData) => ({
        ...prevFormData,
        duration: parentFormData.duration,
      }));
    }
  }, [isEditMode, parentFormData.duration]);

  // Fetch duration list for dropdown
  const getDurationList = async () => {
    try {
      const res = await open_api_without_auth.get("/session/duration_list");
      const durationResultList = res.data.results;
      setDurationList(durationResultList);

      // Create a map of duration_name to id
      const durationNameToIdMap = {};
      durationResultList.forEach((item) => {
        durationNameToIdMap[item.duration_name] = item.id;
      });
      setDurationMap(durationNameToIdMap);
    } catch (error) {
      console.error("Error fetching duration list:", error);
      onSubmitStatus &&
        onSubmitStatus("failure", "Error fetching duration list");
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle dropdown changes
  const handleDropdownChange = (selectedValue) => {
    setFormData({ ...formData, duration: selectedValue });
  };

  // Handle checkbox changes
  const handleCheckboxChange = () => {
    setIsUserInfoRequired((prev) => !prev); // Toggle the checkbox value
  };

  // Validate form fields
  const validateForm = () => {
    const errors = {};
    if (!formData.sessionName) errors.sessionName = "Session name is required";
    if (!formData.sessionsPerPackage)
      errors.sessionsPerPackage = "Number of sessions is required";
    if (!formData.duration) errors.duration = "Duration is required";
    if (!formData.priceInr) errors.priceInr = "Price in INR is required";
    if (!formData.priceUsd) errors.priceUsd = "Price in USD is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateForm()) {
      onSubmitStatus &&
        onSubmitStatus("failure", "Please fill out all required fields");
      return; // Exit if form is not valid
    }

    const response = await addNewPackage();
    if (response === "success") {
      onSubmitStatus &&
        onSubmitStatus(
          "success",
          `Package ${isEditMode ? "updated" : "added"} successfully`
        );
      onClose(); // Close the modal on success
    } else {
      onSubmitStatus &&
        onSubmitStatus(
          "failure",
          `Failed to ${isEditMode ? "update" : "add"} package`
        );
    }
  };

  // Add or update package
  const addNewPackage = async () => {
    try {
      const expertID = Cookies.get("expertID");
      const durationId = durationMap[formData.duration];
      console.log("durationId", durationId);
      const localData = {
        title: formData.sessionName,
        sessions_count: formData.sessionsPerPackage,
        mrp: formData.priceInr,
        selling_price: formData.priceInr,
        mrp_usd: formData.priceUsd,
        selling_price_usd: formData.priceUsd,
        slot_duration_id: durationId,
        expert_id: expertID,
        duration: formatDuration(formData.duration),
        published: true,
        is_user_data_required: isUserInfoRequired, // Add the new field to the payload
      };

      let res;

      if (isEditMode && formData.id) {
        // Update existing package
        res = await open_api_with_user_token.patch(
          `/session/packages/${formData.id}/`,
          localData
        );
      } else {
        // Create new package
        res = await open_api_with_user_token.post(
          "/session/packages/",
          localData
        );
      }

      if (res.status === 200 || res.status === 201) {
        return "success"; // Indicate success
      } else {
        return "failure"; // Indicate failure
      }
    } catch (error) {
      console.error(
        `Error ${isEditMode ? "updating" : "adding"} package:`,
        error
      );
      return "failure"; // Indicate failure
    }
  };

  return (
    <Modal
      title={`${isEditMode ? "Edit" : "Add"} Session Package`}
      onClose={onClose}
    >
      {/* Input Field for Session Name */}
      <InputField
        label="Name of the session package"
        value={formData.sessionName}
        name="sessionName"
        onChange={handleChange}
        error={formErrors.sessionName} // Pass error if present
      />

      {/* Number Field for Sessions Per Package */}
      <NumberField
        label="Number of sessions per package"
        value={formData.sessionsPerPackage}
        name="sessionsPerPackage"
        onChange={handleChange}
        error={formErrors.sessionsPerPackage} // Pass error if present
      />

      {/* Dropdown Field for Duration */}
      <DropdownField
        label="Duration of each slot"
        options={durationList.map((item) => item.duration_name)}
        value={formData.duration}
        onChange={handleDropdownChange}
        error={formErrors.duration} // Pass error if present
      />

      {/* Number Field for Price in INR */}
      <NumberField
        label="Price in INR"
        value={formData.priceInr}
        name="priceInr"
        onChange={handleChange}
        error={formErrors.priceInr} // Pass error if present
      />

      {/* Number Field for Price in USD */}
      <NumberField
        label="Price in USD"
        value={formData.priceUsd}
        name="priceUsd"
        onChange={handleChange}
        error={formErrors.priceUsd} // Pass error if present
      />

      <CustomCheckbox
        checked={isUserInfoRequired}
        onChange={handleCheckboxChange}
        title="Is user information required?"
      />

      {/* Submit Button */}
      <SubmitButton text="Submit" onClick={handleSubmit} />
    </Modal>
  );
};

export default AddPackage;

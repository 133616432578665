import React, { useState } from "react";
import "./SessionQuestionCard.css"; // CSS file for styling
import { useNavigate } from "react-router-dom";
import ToggleButton from "../Button/ToggleButton";
import { open_api_with_user_token } from "../../Utils/network";
import editIcon from "../../assets/Icons/editIcon.svg";

const SessionQuestionCard = ({ id, title, subtitle, isActive }) => {
  const [isToggled, setIsToggled] = useState(isActive);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSwitch = () => {
    setIsToggled(!isToggled);
    updateSessionActiveState(!isToggled);
  };

  const updateSessionActiveState = async (isActive) => {
    try {
      const localData = {
        is_user_data_required: isActive,
      };
      const res = await open_api_with_user_token.patch(
        `/session/packages/${id}/`,
        localData
      );
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        console.log("Status updated");
      }
    } catch (error) {
      console.error("Error updating session active state:", error);
    }
  };

  const handleEditClick = async () => {
    try {
      // Fetch session package questions data
      const response = await open_api_with_user_token.get(
        `/session/dashboard/session_package/${id}/questions/`
      );
      if (response.status === 200) {
        const questionsData =
          response.data.results[0].session_packages_questions;

        // Navigate to the edit page and pass the data
        navigate("/bookings/questions/add", {
          state: {
            questions: questionsData,
            sessionPackageTitle: response.data.results[0].title,
            isEditMode: true,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching session package questions data:", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="session-question-card">
      <div className="session-question-card-left">
        <h3 className="session-question-card-title">{title}</h3>
        <p className="session-question-card-subtitle">{subtitle}</p>
      </div>
      <div className="session-question-card-right">
        <ToggleButton isToggled={isToggled} onToggle={toggleSwitch} />
        <div className="edit-icon-item" onClick={handleEditClick}>
          <img src={editIcon} alt="Edit" />
        </div>
        {/* Uncomment this code if you want to use the dropdown menu
        <div className="menu-container">
          <div className="menu-button" onClick={toggleMenu}>
            <FaEllipsisV />
          </div>
          {isMenuOpen && (
            <div className="dropdown-menu">
              <div className="menu-item" onClick={handleEditClick}>Edit</div>
              <div className="menu-item">Delete</div>
            </div>
          )}
        </div>
        */}
      </div>
    </div>
  );
};

export default SessionQuestionCard;

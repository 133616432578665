import axios from "axios";
import Cookies from "js-cookie";

export const isProduction = false;
const isLocal = false;
export const base_url = isLocal
  ? "http://127.0.0.1:8000/"
  : isProduction
  ? "https://prod.myburgo.com/"
  : "https://api.myburgo.com/";

export const open_api_with_user_token = axios.create({
  baseURL: base_url,
  timeout: 300000,
  headers: {
    Authorization: `Bearer ${Cookies.get("token")}`,
    "Content-Type": `application/json`,
  },
  validateStatus: (status) => status < 500,
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

export const open_api_without_auth = axios.create({
  baseURL: base_url,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) => status < 500,
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

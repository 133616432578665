import React, { useEffect, useState } from "react";
import StatsCard from "../../../components/Card/StatsCard";
import packageIcon from "../../../assets/Icons/packageIcon.svg";
import calendarIcon from "../../../assets/Icons/calendarIcon.svg";
import plusIcon from "../../../assets/Icons/plusIcon.svg";
import calenderIcon from "../../../assets/Icons/calenderIcon.svg";
import "./BookingsDashboardPage.css";
import Chips from "../../../components/Chip/Chips";
import { useNavigate } from "react-router-dom";
import { open_api_with_user_token } from "../../../Utils/network";
import useAuth from "../../../Hooks/useAuth";
import SessionReminder from "../../../components/Banner/SessionReminder";
import {
  addAlert,
  removeAlert,
  formatTime,
  calculateEndTime,
  checkIfButtonShouldBeDisabled,
  calculateStartTime, // Import the helper function
} from "../../../Utils/helper";
import CustomAlert from "../../../components/Alerts/CustomAlert";
import Cookies from "js-cookie";

const BookingsDashboard = () => {
  const navigate = useNavigate();
  const [statsApiData, setStatsApiData] = useState(null);
  const [statsData, setStatsData] = useState([]);
  const { userName } = useAuth();
  const [alerts, setAlerts] = useState([]); // Holds multiple alerts
  const [showAlert, setShowAlert] = useState(false); // Controls visibility of alerts
  const [notificationData, setNotifcationData] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to control button disabled/enabled
  const [isSessionStarted, setIsSessionStarted] = useState(false);

  useEffect(() => {
    getBookingStatData();
    getNotificationForUpcoming();
  }, []);

  useEffect(() => {
    // Check button state whenever notification data changes
    if (notificationData) {
      checkIfButtonShouldBeDisabled(
        notificationData?.slot?.date,
        notificationData?.slot?.time,
        notificationData?.slot?.duration,
        setIsButtonDisabled,
        setIsSessionStarted
      );
    }
  }, [notificationData]); // Re-run when notification data changes

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
    setShowAlert(true); // Show alert when adding a new alert
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
    if (alerts.length <= 1) {
      setShowAlert(false); // Hide alert if there are no more alerts to show
    }
  };

  const getBookingStatData = async () => {
    try {
      const res = await open_api_with_user_token.get(`/session/stats`);
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        setStatsApiData(res.data);
      }
    } catch (error) {
      console.error("Error fetching booking stats data", error);
    }
  };

  const getNotificationForUpcoming = async () => {
    try {
      const expert_id = Cookies.get("expertID");
      const res = await open_api_with_user_token.get(
        `/session/dashboard/experts/${expert_id}/next/booked_slot/`
      );
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        setNotifcationData(res?.data);
      }
    } catch (error) {
      console.error("Error fetching booking stats data", error);
    }
  };

  useEffect(() => {
    if (statsApiData) {
      setStatsData([
        {
          icon: packageIcon,
          title: "Total Packages",
          value: statsApiData.total_packages,
        },
        {
          icon: calendarIcon,
          title: "Total Booked Slots",
          value: statsApiData.total_booked_slots,
        },
      ]);
    }
  }, [statsApiData]);

  const handleChipClick = (linkText) => {
    if (linkText === "AddPackage") {
      navigate("/bookings/packages");
    } else if (linkText === "AddRegistrationQuestions") {
      navigate("/bookings/questions");
    } else if (linkText === "ViewClientBookings") {
      navigate("/bookings/all");
    } else if (linkText === "AddSlots") {
      navigate("/bookings/slots");
    }
  };

  const handleJoinClick = (joinLink) => {
    console.log("isSessionStarted", isSessionStarted);
    if (isSessionStarted) {
      handleAddAlert(
        "Warning",
        "Link will be enabled 30 mins before the session starts",
        "warning",
        true
      );
    } else if (!isButtonDisabled && joinLink) {
      window.open(joinLink, "_blank");
    } else {
      handleAddAlert("Warning", "This session has concluded", "warning", true);
    }
  };

  return (
    <div className="main-container">
      <div className="stats-card-container">
        <h2>Hello {userName} 👋🏼,</h2>
        {/* Display Session Reminder if notificationData is available */}
        {notificationData ? (
          <SessionReminder
            id={notificationData.id}
            sessionTitle={notificationData?.session_purchase?.package?.title}
            expertName={`${notificationData?.user?.first_name} ${notificationData?.user?.last_name}`}
            sessionTime={formatTime(notificationData?.slot?.time)}
            startTime={calculateStartTime(
              notificationData?.slot?.date,
              notificationData?.slot?.time
            )}
            endTime={calculateEndTime(
              notificationData?.slot?.date,
              notificationData?.slot?.time,
              notificationData?.slot?.duration
            )}
            onJoinClick={() =>
              handleJoinClick(notificationData?.jitsi_meeting_link)
            }
            isButtonDisabled={isButtonDisabled} // Pass the button state
            isSessionStarted={isSessionStarted} // Pass the session started state
          />
        ) : (
          <></>
        )}
        <StatsCard data={statsData} />
        <div className="chips-container">
          <Chips
            icon={plusIcon}
            text="Add session packages"
            onClick={() => handleChipClick("AddPackage")}
          />
          <Chips
            icon={plusIcon}
            text="Add Slots"
            onClick={() => handleChipClick("AddSlots")}
          />
          <Chips
            icon={plusIcon}
            text="Add Registration Questions"
            onClick={() => handleChipClick("AddRegistrationQuestions")}
          />
          <Chips
            icon={calenderIcon}
            text="View Client bookings"
            onClick={() => handleChipClick("ViewClientBookings")}
          />
        </div>
      </div>
      {showAlert &&
        alerts.map((alert) => (
          <CustomAlert
            key={alert.id}
            title={alert.title}
            message={alert.message}
            onClose={() => handleRemoveAlert(alert.id)}
            autoClose={alert.autoClose}
            type={alert.type}
          />
        ))}
    </div>
  );
};

export default BookingsDashboard;

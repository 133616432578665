import { Navigate } from "react-router-dom";
import useAuth from "../Hooks/useAuth";
import LoadingOverlay from "../components/Loading/LoadingOverlay";
import { useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (isAuthenticated === null) {
    return <LoadingOverlay />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;

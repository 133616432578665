import React, { useState } from "react";
import CalendarWithSlots from "../../../components/Card/CalendarWithSlots";
import "./AllSlots.css"; // Import styles for the AllSlots component
import Chips from "../../../components/Chip/Chips";
import addIcon from "../../../assets/Icons/plusIcon.svg";
import AddSlots from "./AddSlots";
import { addAlert, removeAlert } from "../../../Utils/helper";
import CustomAlert from "../../../components/Alerts/CustomAlert";

const AllSlots = () => {
  // Sample data for the slots
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alerts, setAlerts] = useState([]); // Holds multiple alerts
  const [showAlert, setShowAlert] = useState(false); // Controls visibility of alerts
  const [refreshSlots, setRefreshSlots] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSubmitStatus = (status, message) => {
    if (status === "success") {
      handleAddAlert("Action Successful", message, "success", true);
      setRefreshSlots((prev) => !prev);
    } else {
      handleAddAlert("Action Failure", message, "error", true);
      setRefreshSlots((prev) => !prev);
    }
  };

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
    setShowAlert(true); // Show alert when adding a new alert
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
    if (alerts.length <= 1) {
      setShowAlert(false); // Hide alert if there are no more alerts to show
    }
  };

  return (
    <div className="all-slots-page">
      <div className="all-slots-page-header">
        <h1>All Slots</h1>
        <Chips icon={addIcon} text="Add Slots" onClick={toggleModal} />
      </div>

      <CalendarWithSlots refreshSlots={refreshSlots} />
      {isModalOpen && (
        <AddSlots onClose={toggleModal} onSubmitStatus={handleSubmitStatus} />
      )}
      {showAlert &&
        alerts.map((alert) => (
          <CustomAlert
            key={alert.id}
            title={alert.title}
            message={alert.message}
            onClose={() => handleRemoveAlert(alert.id)}
            autoClose={alert.autoClose}
            type={alert.type}
          />
        ))}
    </div>
  );
};

export default AllSlots;

import React, { useState, useEffect } from "react";
import "./QuestionCard.css";
import { FaTrashAlt } from "react-icons/fa";
import ToggleButton from "../Button/ToggleButton";
import dragHandleIcon from "../../assets/Icons/dragHandleIcon.svg"; // 6-dots icon

const QuestionCard = ({
  questionData,
  dragHandleProps,
  onDelete,
  onUpdate,
}) => {
  const [questionType, setQuestionType] = useState(
    questionData.type || "Multiple choice"
  );
  const [options, setOptions] = useState(
    questionData.options || [{ id: `option-1`, text: "Option 1" }]
  );
  const [isRequired, setIsRequired] = useState(
    questionData.isRequired || false
  );
  const [text, setText] = useState(questionData.text || "");

  // Update the parent component when any state changes
  useEffect(() => {
    onUpdate(questionData.id, {
      ...questionData,
      text,
      type: questionType,
      isRequired,
      options,
    });
  }, [text, questionType, isRequired, options]);

  const handleQuestionTypeChange = (e) => {
    const newType = e.target.value;
    setQuestionType(newType);
    if (newType !== "Multiple choice") {
      setOptions([]); // Clear options if question type is not Multiple choice
    }
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = { ...newOptions[index], text: value }; // Preserve option id
    setOptions(newOptions);
  };

  const addOption = () => {
    const newOption = {
      id: `option-${options.length + 1}`,
      text: "",
    };
    setOptions([...options, newOption]);
  };

  const deleteOption = (index) => {
    setOptions(options.filter((_, i) => i !== index));
  };

  const toggleRequired = () => {
    setIsRequired(!isRequired);
  };

  return (
    <div className="question-card">
      {/* Drag Handle Area */}
      <div className="drag-handle-container" {...dragHandleProps}>
        <img src={dragHandleIcon} alt="Drag Handle" className="drag-handle" />
      </div>

      {/* Question Input and Dropdown */}
      <div className="question-row">
        <input
          type="text"
          className="question-input"
          placeholder="Question"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <select
          className="question-type-dropdown"
          value={questionType}
          onChange={handleQuestionTypeChange}
        >
          <option value="Multiple choice">Multiple choice</option>
          <option value="Text">Text</option>
        </select>
      </div>

      {/* Options Section */}
      {questionType === "Multiple choice" && (
        <div className="options-section">
          {options.map((option, index) => (
            <div className="option-row" key={option.id}>
              <input
                type="text"
                className="option-input"
                placeholder={`Option ${index + 1}`}
                value={option.text}
                onChange={(e) => handleOptionChange(index, e.target.value)}
              />
              <button
                className="delete-option"
                onClick={() => deleteOption(index)}
              >
                &times;
              </button>
            </div>
          ))}
          {/* Add Option Button */}
          <div className="add-option-row">
            <button className="add-option" onClick={addOption}>
              Add Option
            </button>
            {/* <span>or</span>
            <button className="add-other">Add Other</button> */}
          </div>
        </div>
      )}

      {/* Divider */}
      <div className="divider" />

      {/* Bottom Actions */}
      <div className="bottom-actions">
        <FaTrashAlt
          className="action-icon"
          title="Delete"
          onClick={() => onDelete(questionData.id)} // Call onDelete prop with the question ID
        />
        <div className="vertical-divider" />
        <div className="required-section">
          <span>Required</span>
          {/* Use the custom ToggleButton component */}
          <ToggleButton isToggled={isRequired} onToggle={toggleRequired} />
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;

import React, { useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { Outlet } from "react-router-dom";
import "./DashboardLayout.css";

const DashboardLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`dashboard-layout ${isSidebarOpen ? "sidebar-open" : ""}`}>
      {/* Sidebar Toggle Button for Mobile */}
      <button
        className={`sidebar-toggle ${isSidebarOpen ? "open" : ""}`}
        onClick={toggleSidebar}
      >
        ☰
      </button>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="content" onClick={() => setIsSidebarOpen(false)}>
        <Outlet /> {/* This will render the nested routes */}
      </div>
    </div>
  );
};

export default DashboardLayout;
